import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import ComponentParser from "../../utils/ComponentParser"
import FeaturedBooks from "../../components/Blocks/FeaturedBooks"
import SelectSearch from "react-select-search"
import AngleIcon from "../../components/svgs/AngleIcon"

const book = ( { data: { book } } ) => {
    
    const imageData       = getImage(book?.featuredImage?.node?.localFile);
    const purchaseOptions = [];

    book?.bookMeta.bookUrls.map((option, index) => {
		purchaseOptions.push( { name: option.bookSellerName, value:option.bookSellerUrl  })
	});

    function handlePurchaseSelect( option ) {
        window.open(
            option,
            '_blank'
        );
    }

	return (
		<Layout>
			<Seo
                title={book?.title}
				canonical={book?.seo?.canonical}
				uri={book?.uri}
				socialImage={book?.featuredImage?.node}
				description={book?.seo?.metaDesc ? book?.seo.metaDesc : book?.excerpt.replace(/(<([^>]+)>)/gi, "") }
            />
            
            <div className="w-full max-w-6xl px-4 py-4 md:py-12 lg:py-24 mx-auto md:flex">
                <div className="md:mr-20">
                    <GatsbyImage
                        image={imageData}
                        width="260"
                    />
                </div>
                <div>
                    <h1>{book?.title}</h1>
                    <div className="mt-4 pb-16 md:px-0">
                        {book?.blocks &&
                        book?.blocks.map((block, i) => {
                            return (
                                <div key={i}>
                                    <ComponentParser
                                        block={block}
                                        page={book}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    <div className="relative">
                        <AngleIcon 
                            className="absolute left-48 top-5 z-10"
                        />
                        <SelectSearch
                            options={purchaseOptions}
                            placeholder="Purchase Options"
                            onChange={handlePurchaseSelect}
                        />
                    </div>
                </div>
            </div>

            <FeaturedBooks 
                label="Featured Books"
            />
				
		</Layout>
	);
}

export const bookQuery = graphql`
	query BookById(
		$id: String!
	) {
		book: wpBook( id: { eq: $id } ) {
			... BookContent
		}
	}
`
export default book